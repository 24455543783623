import { render, staticRenderFns } from "./market-concept-insurance.vue?vue&type=template&id=0c129566&scoped=true&"
import script from "./market-concept-insurance.vue?vue&type=script&lang=ts&"
export * from "./market-concept-insurance.vue?vue&type=script&lang=ts&"
import style0 from "./market-concept-insurance.vue?vue&type=style&index=0&id=0c129566&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c129566",
  null
  
)

export default component.exports