





































































































































































































































import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import operatingModes from '@/config/operating-modes';
import IRiskAssesment from '@/model/IRiskAssesment';
import errorMessage from '@/components/form/error-message.vue';
import priceCalculator from '@/service/price-calculator';
import FormSwitch from '../../components/form/form-switch.vue';

export default Vue.extend({
  name: 'sample-collection',
  components: {
    FormSwitch,
    popUpModal,
    vSelect,
    errorMessage,
  },
  props: ['assesment', 'isAPIObserver', 'insuranceData', 'caseStatus', 'AMSAPISendStatus'],
  data() {
    return {
      operatingModes,
      operatingMode: '',
      totalSampleValues: 0,
      noOfSampeQuestion: '',
      hasPreciousMetals: '',
      scope: '',
      ...this.insuranceData,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      componentLoaded: false,
      isSelectOpen: false,
      haveBussiness: false,
      haveTransport: false,
      remarks: '',
      processName: '',
      scopeSelected: true,
    };
  },
  computed: {
    changeData(): any {
      return [this.totalSampleValues, this.noOfSampeQuestion, this.hasPreciousMetals, this.scope, this.operatingMode].join();
    },
    isBARTTypeDL(): boolean {
      return (this.operatingMode as any)['BART-Gruppe'] === 'DL';
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  watch: {
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getApiData() {
      priceCalculator.getUserAPIData().then((response) => {
        if (response.data !== null) {
          if (this.processName === '') {
            this.processName = response.data.assesment.processName;
          }
        }
      });
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onDropDownClick() {
      this.isSelectOpen = !this.isSelectOpen;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.operatingModeRef as any).open = this.isSelectOpen;
    },
    onClick() {
      if (this.scope === '') {
        this.scopeSelected = false;
        return;
      }
      const data = {
        Beinhaltet: false,
        totalSampleValues: this.totalSampleValues,
        scope: this.scope,
        noOfSampeQuestion: this.noOfSampeQuestion,
        hasPreciousMetals: this.hasPreciousMetals,
        remarks: this.remarks,
        processName: this.processName,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
      };

      const priceData = {
        Beinhaltet: false,
        GesamtWert: this.totalSampleValues,
        Geltungsbereich: this.scope,
        // noOfSampeQuestion: this.noOfSampeQuestion,
        // hasPreciousMetals: this.hasPreciousMetals,
      };
      this.$emit(
        'risk-assesment-change',
        {
          operatingMode: this.operatingMode,
          remarks: this.remarks,
          processName: this.processName,
          haveTransport: this.haveTransport,
          haveBussiness: this.haveBussiness,
        },
        'SAMPLE_COLLECTION_INSURANCE',
      );
      this.$emit('data-changed', 'd6838444-100a-4913-9e43-16328c9047db', priceData, data, 'SAMPLE_COLLECTION_INSURANCE');
    },
    setValues(insuranceData: any) {
      this.processName = insuranceData.processName;
      this.totalSampleValues = insuranceData.totalSampleValues;
      this.scope = insuranceData.scope;
      this.hasPreciousMetals = insuranceData.hasPreciousMetals;
    },
    setAssesment(assesment: any) {
      this.operatingMode = (assesment as IRiskAssesment).operatingMode || '';
      this.remarks = (assesment as IRiskAssesment).remarks || '';
      this.processName = (assesment as IRiskAssesment).processName || '';
      this.haveTransport = (assesment as IRiskAssesment).haveTransport || false;
      this.haveBussiness = (assesment as IRiskAssesment).haveBussiness || false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
  },
  mounted() {
    this.getApiData();
    if (!this.assesment) {
      this.$router.replace('/transport-insurance');
    } else {
      this.setAssesment(this.assesment);
    }
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
    this.$emit(
      'risk-assesment-change',
      {
        operatingMode: this.operatingMode,
        remarks: this.remarks,
        processName: this.processName,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
      },
      'SAMPLE_COLLECTION_INSURANCE',
    );
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
